<template>
  <div class="iframe-youtube">
    <BaseTitle class="iframe-youtube__title" tag="h2" v-if="content.title">{{ content.title }}</BaseTitle>
    <div  class="iframe-youtube__iframe">
      <div>
        <iframe width="560" height="315" :src="content.video" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      </div>
    </div>
  </div>
</template>

<script>
import BaseTitle from '@base/BaseTitle'

export default {
  name: 'ParagraphIframeYoutube',
  props: {
    content: {
      type: Object,
      required: false
    }
  },
  components: {
    BaseTitle
  }
}
</script>

<style lang="scss">
  .iframe-youtube{

    // Iframe

    &__iframe{
      @include aspect-ratio(56rem, 31.5rem);
      width: 100%;
      max-width: 70rem;
      margin-bottom: layout("spacing");

      iframe{
        width: 100%;
        height: 100%;
      }
    }
  }
</style>
