<template>
  <ContainerHub class="hub-paragraphs">
    <template slot="header">
      <HubHeader
        :titleTag="'h1'"
        :title="title"
        :email="email"
        :description="description"
      />
    </template>
    <template slot="body">
      <component
        :is="paragraph.type"
        :content="paragraph.content"
        :key="`paragraph-${index}`"
        :class="'paragraph'"
        v-for="(paragraph, index) in items" />
    </template>
  </ContainerHub>
</template>

<script>
import { mapGetters } from 'vuex'
import ContainerHub from '@containers/ContainerHub'
import HubHeader from './HubHeader'
import ParagraphStructure from '@blocks/paragraphs/ParagraphStructure'
import ParagraphSimpleText from '@blocks/paragraphs/ParagraphSimpleText'
import ParagraphColumnsText from '@blocks/paragraphs/ParagraphColumnsText'
import ParagraphIframeYoutube from '@blocks/paragraphs/ParagraphIframeYoutube'
import ParagraphIframeGooglemap from '@blocks/paragraphs/ParagraphIframeGooglemap'
import ParagraphPublications from '@blocks/paragraphs/ParagraphPublications'

export default {
  name: 'HubParagraphs',
  props: {
    name: {
      type: String,
      required: true
    }
  },
  components: {
    HubHeader,
    ContainerHub,
    ParagraphStructure,
    ParagraphSimpleText,
    ParagraphColumnsText,
    ParagraphIframeYoutube,
    ParagraphIframeGooglemap,
    ParagraphPublications
  },
  data () {
    return {}
  },
  computed: {
    ...mapGetters({
      title: 'page/getTitle',
      description: 'page/getDescription',
      email: 'page/getEmail',
      items: 'paragraphs/getItems'
    })
  }
}
</script>

<style lang="scss">
  .hub-paragraphs{
    .paragraph{
      &:not(:first-of-type){
        // padding-top: layout("spacing");
      }
      &:not(:last-of-type){
        border-bottom: 1px solid tint(colors("font"), 90%);
      }

      .title{
        padding-bottom: layout("spacing");
      }
    }
  }
</style>
