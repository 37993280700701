<template>
  <div class="structure-teams" :class="customClass">

    <div class="structure-teams__header" :style="{'background-color': content.backgroundColor, 'color': content.textColor}" :class="typeClass"  @click="handleExpand">
      <div class="structure-teams__header__title">{{content.name}}</div>
      <div class="structure-teams__header__icon"></div>
    </div>
    <div class="structure-teams__content" v-show="isActive">
      <!-- {{content.items}} -->
      <ParagraphStructureGroup
          :key="item.name"
          :name="item.name"
          :group="item.group || ''"
          :subitems="item.subItems || null"
          v-for="item in content.items"
        />
    </div>
  </div>
</template>

<script>
import ParagraphStructureGroup from './ParagraphStructureGroup'

export default {
  name: 'ParagraphStructureTeam',
  props: {
    content: {
      type: Object,
      required: true
    }
  },
  components: {
    ParagraphStructureGroup
  },
  data () {
    return {
      isActive: this.expand
    }
  },
  computed: {
    customClass () {
      return this.isActive ? 'is-expanded' : 'is-collapsed'
    },
    typeClass () {
      return this.content.type ? `is-${this.content.type}` : ''
    }
  },
  methods: {
    handleExpand () {
      this.isActive = !this.isActive
    }
  }

}
</script>

<style lang="scss">
.structure-teams {
  &__header {
    @media print {
      background-color: transparent !important;
      color: black;
    }
    @include media('medium-large') {
      cursor: pointer;
    }

    @each $key, $value in $teams-colors{
      &.is-#{$key}{
        background-color: $value;
      }
    }
    display: flex;
    padding: .5rem 1rem;
    align-items: center;
    justify-content: space-between;
    border-radius: layout(border-radius);
    background-color: colors(border-dark);
    color: white;

    .is-expanded & {
      border-bottom: 1px solid transparent;
      border-radius: layout(border-radius) layout(border-radius) 0 0;
    }

    &__title {
      @media print {
        font-size: 1.4rem;
        font-weight: font-weights('bold');
      }
      margin: 0;
      max-width: 75%;
    }

    &__icon {
      @media print {
        display: none !important;
      }
      width: 2.5rem;
      height: 2.5rem;
      position: relative;

      &::before, &::after {
        content: '';
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        transform:translate(-50%, -50%);
        background-color: white;
        height: 0.2rem;
        width: 2rem;
        border-radius: .4rem;
      }
      &::after {
        transform: translate(-50%, -50%) rotate(90deg)
      }
      .is-expanded & {
        &::after {
          display: none;
        }
      }
    }

  }
  &__content {
    @media print {
      display: block !important;
    }
    .structure-group {
      @media print {
        color: black;
        font-size: 1.2rem;
      }
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      padding: 0.5rem 1rem;
      font-size: 1.5rem;
      color: colors(primary);

      &:nth-of-type(odd) {
        @media screen {
          background-color: colors(foreground)
        }
      }
      &:last-child {
        border-radius:0 0 layout(border-radius) layout(border-radius) ;
      }
      &.has-subitems {
        color: colors(border-dark);
        .is-triangle {
          @include add-triangle(right, colors(primary), .3em);
          display: inline-block;
          margin-right: 0.5rem;
        }
        &.is-expanded {
          .is-triangle {
          @include add-triangle(bottom, colors(primary), .3em);
          display: inline-block;
          margin-right: 0.1rem;
          margin-bottom: -0.1rem;
        }
        }
      }
      &__actions {
        @media print {
          display: none !important;
        }
        display: flex;
      }
      &__subitem {
        width: 100%;
        margin-top: 1rem;
        padding-top: 1rem;
        border-top:1px solid colors(border);
        .icon {
          margin-right: 0.5rem;
          &:before {
            color: colors(primary);
            font-size: 1rem;
          }
        }
        &__item {
          @media print {
            font-size: 1.2rem;
          }
          display: block;
          padding: 0.5rem;
          font-weight: font-weights('normal');

          &:hover {
            color: colors(primary)
          }
        }
      }
    }
  }
}
</style>
