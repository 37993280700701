<template>
  <div class="simple-text">
    <BaseTitle class="simple-text__title" tag="h2" v-if="content.title">{{ content.title }}</BaseTitle>
    <BaseWysiwyg class="simple-text__content" :content="content.wysiwyg" v-if="content.wysiwyg" />
  </div>
</template>

<script>
import BaseTitle from '@base/BaseTitle'
import BaseWysiwyg from '@base/BaseWysiwyg'
export default {
  name: 'ParagraphSimpleText',
  props: {
    content: {
      type: Object,
      required: false
    }
  },
  components: {
    BaseTitle,
    BaseWysiwyg
  }
}
</script>

<style lang="scss">
  .simple-text{}
</style>
