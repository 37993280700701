<template>
  <div class="publications">
    <BaseTitle class="publications__title" tag="h2" v-if="content.title">{{ content.title }}</BaseTitle>
    <div  class="publications__grid">
      <div
        class="publications__iframe"
        :key="`publication-${index}`"
        v-for="(publication, index) in content.publications">
        <div>
          <iframe
            :src="publication"
            title="issuu.com"
            frameborder="0"
            allowfullscreen
            webkitallowfullscreen
            mozallowfullscreen
            msallowfullscreen>
          </iframe>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseTitle from '@base/BaseTitle'

export default {
  name: 'ParagraphPublications',
  props: {
    content: {
      type: Object,
      required: false
    }
  },
  components: {
    BaseTitle
  }
}
</script>

<style lang="scss">
  .publications{

    // Grid

    &__grid{
      @include media("small"){
        flex-direction: column;
      }
      @include media("medium-large"){
        margin: 0 layout("spacing")*-0.5;
      }

      display: flex;
      justify-content: flex-start;
      align-items: stretch;
      flex-wrap: wrap;
      padding-bottom: layout("spacing");
    }

    // Iframe

    &__iframe{
      @include aspect-ratio(52.5rem, 37.1rem);
      @include media("small"){
        width: 100%;
      }
      @include media("medium-large"){
        width: calc(100% / 3);
      }

      flex: 0 1 auto;

      > div{
        padding: layout("spacing")/2;
      }

      iframe{
        width: 100%;
        height: 100%;
      }
    }
  }
</style>
