<template>
  <div :class="classNames">
    <div class="structure-column__main">
        <BaseTitle tag="h2">{{ title }}</BaseTitle>
        <ul class="structure-column__groups">
          <ParagraphStructureGroup
            :tag="'li'"
            :key="item.name"
            :name="item.name"
            :group="item.group"
            v-for="item in groups"
          />
        </ul>
    </div>
    <div class="structure-column__teams" v-if="teams">
      <ParagraphStructureTeam
        v-for="item in teams"
        :key="item.name"
        :content="item"
       />
    </div>
  </div>
</template>

<script>
import ParagraphStructureGroup from './ParagraphStructureGroup'
import BaseTitle from '@base/BaseTitle'
import ParagraphStructureTeam from '@blocks/paragraphs/ParagraphStructureTeam'

export default {
  name: 'ParagraphStructureColumn',
  props: {
    title: {
      type: String,
      required: true
    },
    groups: {
      type: Array,
      required: true
    },
    teams: {
      type: Array,
      required: false
    },
    index: {
      type: Number,
      required: true
    }
  },
  components: {
    BaseTitle,
    ParagraphStructureGroup,
    ParagraphStructureTeam
  },
  computed: {
    classNames () {
      return [
        'structure-column',
        `structure-column-${this.index}`
      ]
    }
  }
}
</script>

<style lang="scss">
  .structure-column{

    // Title

    .title{
      font-size: 2.2rem;
      text-align: center;
    }

    .structure-teams {
      margin-bottom: 0.5rem;
    }
  }
</style>
