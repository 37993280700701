<template>
  <div class="iframe-googlemap">
    <BaseTitle class="iframe-googlemap__title" tag="h2" v-if="content.title">{{ content.title }}</BaseTitle>
    <div class="iframe-googlemap__iframe">
      <div>
        <iframe :src="content.googlemap" width="600" height="450" frameborder="0" style="border:0" allowfullscreen></iframe>
      </div>
    </div>
  </div>
</template>

<script>
import BaseTitle from '@base/BaseTitle'

export default {
  name: 'ParagraphIframeGooglemap',
  props: {
    content: {
      type: Object,
      required: false
    }
  },
  components: {
    BaseTitle
  }
}
</script>

<style lang="scss">
  .iframe-googlemap{

    // Iframe

    &__iframe{
      width: 100%;
      height: 45rem;
      margin-bottom: layout("spacing");

      > div{
        height: 100%;
      }

      iframe{
        width: 100%;
        height: 100%;
      }
    }
  }
</style>
