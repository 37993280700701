<template>
  <component
    :is="tag"
    class="structure-group"
    :class="{ 'has-subitems' : subitems, 'is-expanded' : showSubitems }"
    @mouseover="showActions = true"
    @mouseleave="showActions = false"
    @click="subitems && toogleSubitems()">
    <span>
      <span class="is-triangle" v-if="subitems"></span>
      {{ name }}
    </span>
    <div class="structure-group__actions" v-show="showActions" >
      <div class="structure-group__group">
        <router-link :to="routeGroup" class="structure-group__actions__item"
          @mouseover.native="showTooltipGroup = true"
          @mouseleave.native="showTooltipGroup = false">
          <ElementTooltip
            :text="'View Group'"
            :active="showTooltipGroup"
          />
          <BaseIcon
            type="zoom"
            :text="'View group'"
          />
        </router-link>
      </div>
      <div class="structure-group__members">
        <router-link :to="routeMembers" class="structure-group__actions__item"
          @mouseover.native="showTooltipUsers = true"
          @mouseleave.native="showTooltipUsers = false">
          <ElementTooltip
            :text="'View members'"
            :active="showTooltipUsers"
          />
          <BaseIcon
            type="user-filter"
            :text="'View members'"
          />
        </router-link>
      </div>
    </div>
    <div class="structure-group__subitem" v-if="showSubitems && subitems">
      <BaseButton
      class="structure-group__subitem__item"
        :key="subtitem.name"
        :icon="'chevron-right'"
        icon-position="left"
        :route="subtitem.group"
        clear
        v-for="subtitem in subitems">
        {{subtitem.name}}
      </BaseButton>
    </div>

  </component>
</template>

<script>
import ElementTooltip from '@elements/ElementTooltip'
import BaseIcon from '@base/BaseIcon'
import BaseButton from '@base/BaseButton'

export default {
  name: 'ParagraphStructureGroup',
  props: {
    tag: {
      type: String,
      required: false,
      default: 'div'
    },
    name: {
      type: String,
      required: true
    },
    group: {
      type: String,
      required: true
    },
    subitems: {
      type: Array,
      required: false
    }
  },
  components: {
    ElementTooltip,
    BaseIcon,
    BaseButton
  },
  data () {
    return {
      showTooltipGroup: false,
      showTooltipUsers: false,
      showActions: false,
      showSubitems: false
    }
  },
  computed: {
    routeMembers () {
      return `/contacts/list?filters=${this.group}`
    },
    routeGroup () {
      return `/contacts/groups-teams/details/${this.group}`
    }
  },
  methods: {
    toogleSubitems () {
      this.showSubitems = !this.showSubitems
    }
  }
}
</script>

<style lang="scss">
  .structure-group {
    @include media('medium-large') {
      cursor: pointer;
    }

    > span {
      max-width: 80%;
    }

    &__actions {
      display: flex;
      .icon:before {
        color: inherit;
        font-size: 1.4rem;
      }
      &__item {
        color: inherit;
        margin-left: 1rem;
        position: relative;

        &:hover {
          color: inherit
        }
      }
    }
  }

</style>
