<template>
  <div class="structure">
    <div
      class="structure__row"
      :class="`is-${row.level}`"
      :key="`row-${index}`"
      v-for="(row, index) in content.rows">

      <ParagraphStructureColumn
        :key="column.title"
        :title="column.title"
        :groups="column.groups"
        :teams="column.teams || null"
        :index="index"
        v-for="(column, index) in row.columns"
      />
    </div>
  </div>
</template>

<script>
import ParagraphStructureColumn from './ParagraphStructureColumn'
export default {
  name: 'ParagraphStructure',
  props: {
    content: {
      type: Object,
      required: true
    }
  },

  components: {
    ParagraphStructureColumn
  },
  methods: {}
}
</script>

<style lang="scss">
.structure{
@include media(small) {
  min-width: 50rem;
  overflow-x: auto;

  &__row {
  min-width: 100rem;

  }

}
  // Row

  &__row{
    display: flex;
    align-items: flex-start;

    > .structure-column{
      flex: 0 1 auto;
      width: calc(100% / 3);
      padding: .6rem;
    }

    @each $key, $value in $structure-colors{
      &.is-#{$key}{
        .structure-column{
          &__main {
            background-color: $value;
            @media print {
              background-color: transparent !important;
              border: 1px solid $value;
            }
            .title{
              @media print {
                color: black;
              }
            }
          }
           &__groups  {
            .structure-group {
              background-color: shade( $value, 10%);
              @media print {
                background-color: transparent !important;
                border: 1px solid lightgray;
                color: black;
                &__actions{
                  display: none !important;
                }
              }
            }
          }
        }
      }
    }

    &.is-approval, &.is-steering {
      justify-content: center;
      color: white;
    }

    &.is-working {
      justify-content: flex-start;
    }

    &.is-teams{
      .structure-column {
          &__groups {
          .structure-group {
            background-color: white;
          }
        }
      }
    }

    .structure-column{
      &__main {
          border-radius: .4rem;
          padding: 1rem;
          .title{
            margin-bottom: 1rem;
          }
      }
      &__groups {
        @include no-type-list();
        .structure-group {
          border-radius: .4rem;
          padding: 1rem;
          display: flex;
          justify-content: space-between;
          align-items: flex-start;

          > * {
            flex: 0 1 auto
          }

          & + .structure-group {
            margin-top: 0.5rem;
          }

        }
      }
      &__teams {
        margin-top: 1rem;
      }

    }
  }
}
</style>
