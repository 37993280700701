<template>
  <div class="wysiwyg" v-html="content"></div>
</template>

<script>
export default {
  name: 'BaseWysiwyg',
  props: {
    content: {
      type: String,
      required: true
    }
  }
}
</script>

<style lang="scss">
  .wysiwyg{
    h3, h4, h5, h6{
      @include media("small"){
        font-size: 1.6rem;
      }
      @include media("large"){
        font-size: 1.4rem;
      }
      margin: 0;
      padding-bottom: layout("spacing");
      font-weight: font-weights("bold");
    }

    p{
      @include media("small"){
        font-size: 1.6rem;
      }
      @include media("large"){
        font-size: 1.4rem;
      }
      margin: 0;
      padding-bottom: layout("spacing");
    }

    ul{
      @include no-type-list;
      margin-bottom: 0;
      padding-bottom: layout("spacing");
    }

    li{
      @include media("small"){
        font-size: 1.6rem;
      }
      @include media("large"){
        font-size: 1.4rem;
      }
      position: relative;
      margin-left: 2rem;

      &:before{
        @include size(0.6rem);
        @include absolute-center(vertical);
        left: -2rem;
        content: "";
        display: block;
        background-color: colors("primary");
        border-radius: 50%;
      }
    }

    a{
      @include media("small"){
        font-size: 1.6rem;
      }
      @include media("large"){
        font-size: 1.4rem;
      }
      color: colors("primary");
      font-weight: font-weights("bold");
    }

    iframe{
      max-width: 60rem;
      width: 100%;
    }
  }
</style>
