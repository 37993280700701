<template>
  <div class="columns-text">
    <BaseTitle
      class="columns-text__title"
      tag="h2"
      v-if="content.title">
      {{ content.title }}
    </BaseTitle>
    <div class="columns-text__grid">
      <BaseWysiwyg
        :content="wysiwyg"
        :class="'columns-text__content'"
        :key="`wysiwyg-${index}`"
        v-for="(wysiwyg, index) in content.columns"
      />
    </div>

  </div>
</template>

<script>
import BaseTitle from '@base/BaseTitle'
import BaseWysiwyg from '@base/BaseWysiwyg'
export default {
  name: 'ParagraphColumnsText',
  props: {
    content: {
      type: Object,
      required: false
    }
  },
  components: {
    BaseTitle,
    BaseWysiwyg
  }
}
</script>

<style lang="scss">
  .columns-text{

    // Grid

    &__grid{
      @include media("small"){
        flex-direction: column;
      }
      @include media("medium-large"){
        margin: 0 layout("spacing")*-0.5;
      }

      display: flex;
      justify-content: flex-start;
      align-items: stretch;

      > * {
        @include media("medium-large"){
          padding: 0 layout("spacing")/2;
        }
        flex: 0 1 auto;
      }
    }
  }
</style>
