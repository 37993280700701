<template>
  <div class="content-page">
    <HubParagraphs
      name="paragraphs"
      v-if="loaded" />
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import HubParagraphs from '@blocks/hubs/HubParagraphs'
export default {
  name: 'ContentView',
  components: {
    HubParagraphs
  },
  data () {
    return {
      loaded: false
    }
  },
  watch: {
    '$route': 'fetchData'
  },
  mounted () {
    this.fetchData()
  },
  methods: {
    ...mapActions({
      fetchGetPage: 'page/fetchGetPage'
    }),
    fetchData () {
      this.loaded = false
      let request = { service: this.$route.meta.service }

      if (this.$route.meta.service === 'page') {
        request = {
          service: this.$route.meta.service,
          params: {
            slug: this.$route.params.slug
          }
        }
      }

      if (this.$route.meta.service) {
        this.fetchGetPage(request).then(() => {
          this.loaded = true
        })
      }
    }
  }
}
</script>
